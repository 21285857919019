import React, { memo, useEffect, useState } from "react";

import {
  ZoomableGroup,
  ComposableMap,
  Geographies,
  Geography,
  Sphere,
  Graticule,
} from "react-simple-maps";

import { csv } from "d3-fetch";
import { scaleLinear } from "d3-scale";

const geoUrl =
  "https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json";

const colorScale = scaleLinear().domain([0, 100]).range(["#ffedea", "#ff5233"]);

const getFillColor = (dValue) => {
  const defaultColor = "lightgray";
  if (!dValue || dValue === 0) return defaultColor;
  return colorScale(dValue);
};

const MapChartTikTok = ({ setTooltipContent }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    csv("/csv/tiktok-government-requests.csv").then((data) => {
      setData(data);
    });
  }, []);

  return (
    <>
      <ComposableMap data-tip="" projectionConfig={{ scale: 200 }}>
        <ZoomableGroup>
          <Sphere stroke="#E4E5E6" strokeWidth={0.5} />
          <Graticule stroke="#E4E5E6" strokeWidth={0.5} />
          <Geographies geography={geoUrl}>
            {
              ({ geographies }) =>
                geographies.map((geo) => {
                  const d = data.find((s) => s.ISO3 === geo.properties.ISO_A3);

                  const accountsAffected = d
                    ? parseInt(d["First Half 2020 Total Accounts Affected"])
                    : 0;
                  const requestsUserInfo = d
                    ? parseInt(
                        d["First Half 2020 Total Requests for User Information"]
                      )
                    : 0;
                  const requestsContentRestriction = d
                    ? parseInt(
                        d[
                          "First Half 2020 Total Requests for Content Restriction/Removal"
                        ]
                      )
                    : 0;
                  const requestsTotal =
                    requestsUserInfo + requestsContentRestriction;

                  return (
                    <Geography
                      key={geo.rsmKey}
                      geography={geo}
                      onMouseEnter={() => {
                        const { NAME } = geo.properties;
                        setTooltipContent(
                          NAME +
                            ":<br><br>" +
                            requestsUserInfo.toLocaleString("en") +
                            " government requests for user info <br>" +
                            requestsContentRestriction.toLocaleString("en") +
                            " government requests for content restriction/removal <br>" +
                            accountsAffected.toLocaleString("en") +
                            " user accounts affected"
                        );
                      }}
                      onMouseLeave={() => {
                        setTooltipContent("");
                      }}
                      style={{
                        default: {
                          fill: getFillColor(requestsTotal),
                          outline: "none",
                        },
                        hover: {
                          fill: "orange",
                          outline: "none",
                        },
                        pressed: {
                          fill: "#E42",
                          outline: "none",
                        },
                      }}
                    />
                  ); // close return <Geography/>
                }) // close geographies.map
            }
          </Geographies>
        </ZoomableGroup>
      </ComposableMap>
    </>
  );
};

export default memo(MapChartTikTok);
