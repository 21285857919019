import React, { Component } from "react";

export class Main extends Component {
  render() {
    return (
      <div className="wrapper_centering" style={{ fontFamily: "Poppins" }}>
        <br />
        <br />

        <div className="container_centering">
          <img
            src="img/logo.png"
            style={{ width: 55, height: 55, marginLeft: 40 }}
            alt="logo"
          />

          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-lg-6 align-items-center">
                <div className="main_title_1" style={{ marginLeft: 50 }}>
                  <h3 style={{ fontSize: 62 }}>
                    GroveFlow <br /> Sunshine
                  </h3>{" "}
                  <br />
                  <p style={{ fontSize: 26, fontWeight: "bold" }}>
                    How do we know when governments are requesting or
                    restricting data?
                  </p>
                </div>
              </div>

              <div className="col-xl-6 col-lg-6 align-items-center">
                <div className="main_title_1">
                  <div className="App">
                    <br />
                    <br />

                    <button
                      id="submit_button"
                      type="submit"
                      className="submit"
                      onClick={() => window.open("/tiktok", "_self")}
                    >
                      TikTok Data Transparency
                    </button>
                    <br />
                    <br />
                    <br />
                    <button
                      id="submit_button"
                      type="submit"
                      className="submit"
                      onClick={() => window.open("/google", "_self")}
                    >
                      Google Data Transparency
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
