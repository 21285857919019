import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";

import { Main } from "./components/Main";
import SunshineTikTok from "./components/SunshineTikTok";
import SunshineGoogle from "./components/SunshineGoogle";

class App extends Component {
  render() {
    return (
      <Router>
        <Switch>
          {/* renders first route that matches url */}
          <Route
            exact
            path="/"
            render={(props) => (
              <div>
                <br />
                <br />
                <Main> </Main>
              </div>
            )}
          />
          <Route exact path="/tiktok" component={SunshineTikTok} />
          <Route exact path="/google" component={SunshineGoogle} />
        </Switch>
      </Router>
    );
  }
}

export default App;
