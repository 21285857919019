import React, { memo, useState } from "react";
import ReactTooltip from "react-tooltip";

import MapChartTikTok from "./MapChartTikTok";

import "./SunshineStyles.css";

const SunshineTikTok = () => {
  const [content, setContent] = useState("");
  document.body.style = "background: white";

  return (
    <div style={{ fontFamily: "Poppins" }}>
      <br />
      <br />
      <div style={{ textAlign: "center" }}>
        <b style={{ fontSize: "18pt" }}>TikTok:</b>
        <br />
        Government Requests for User Information and Content Restriction/Removal
        <br />
        <br />
        <i>January 1, 2020 to June 30, 2020</i>
        <br />
        <br />
        <span style={{ fontSize: "12pt" }}>
          Raw Data:{" "}
          <a href="https://www.tiktok.com/safety/resources/transparency-report-2020-1">
            TikTok Transparency Report
          </a>
        </span>
      </div>

      <MapChartTikTok setTooltipContent={setContent} />
      <ReactTooltip html={true}>{content}</ReactTooltip>

      <br />
      <div style={{ textAlign: "center", fontSize: "12pt" }}>
        Created by{" "}
        <a href="https://sunshine.groveflow.com">GroveFlow Sunshine.</a>&nbsp;
        Questions/comments?{" "}
        <b>
          <a href="mailto:hello@groveflow.com">hello@groveflow.com</a>
        </b>
      </div>
      <br />
      <br />
      <br />
    </div>
  );
};

export default memo(SunshineTikTok);
