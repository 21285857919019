import React, { memo, useState } from "react";
import ReactTooltip from "react-tooltip";

import MapChartGoogle from "./MapChartGoogle";

import "./SunshineStyles.css";

// https://stackoverflow.com/questions/53371356/how-can-i-use-react-hooks-in-react-classic-class-component

const SunshineGoogle = () => {
  const [content, setContent] = useState("");
  document.body.style = "background: white";

  return (
    <div style={{ fontFamily: "Poppins" }}>
      <br />
      <br />
      <div style={{ textAlign: "center" }}>
        <b style={{ fontSize: "18pt" }}>Google:</b>
        <br />
        Government Requests for User Information and Content Restriction/Removal
        <br />
        <br />
        <i>July 1, 2019 to December 31, 2019</i>
        <br />
        <br />
        <span style={{ fontSize: "12pt" }}>
          Raw Data:{" "}
          <a href="https://transparencyreport.google.com/">
            Google Transparency Report
          </a>
        </span>
      </div>

      <MapChartGoogle setTooltipContent={setContent} />
      <ReactTooltip html={true}>{content}</ReactTooltip>

      <br />
      <div style={{ textAlign: "center", fontSize: "12pt" }}>
        Created by{" "}
        <a href="https://sunshine.groveflow.com">GroveFlow Sunshine.</a>&nbsp;
        Questions/comments?{" "}
        <b>
          <a href="mailto:hello@groveflow.com">hello@groveflow.com</a>
        </b>
      </div>
      <br />
      <br />
      <br />
    </div>
  );
};

export default memo(SunshineGoogle);
